import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

const textAnim = gsap.utils.toArray(".text-anim");

setTimeout(function () {
   textAnim.forEach((text, i) => {
      new SplitType(text, {
         types: "lines",
         tagName: "span"
      });
   });
}, 500);

const textAnimBox = gsap.utils.toArray(".text-anim__box");

setTimeout(function () {
   textAnimBox.forEach((text, i) => {
      const textLines = gsap.utils.toArray(".line", text);
      textLines.forEach((line, i) => {
         let delay = i + 1;
         if (delay < 10) {
            delay = "." + delay;
         }
         if (delay >= 10 && delay < 100) {
            delay = (delay + '')[0] + "." + (delay + '')[1];
         }

         line.style.animationDelay = delay + "s";

         mm.add("(min-width: 480px)", () => {
            gsap.to(line, {
               animationPlayState: 'running',
               scrollTrigger: {
                  trigger: text,
                  //scroller: scroller,
                  start: "20% bottom",
                  end: "bottom bottom",
                  //markers: true
               }
            })
         });
         mm.add("(max-width: 480px)", () => {
            gsap.to(line, {
               animationPlayState: 'running',
               scrollTrigger: {
                  trigger: text,
                  //scroller: scroller,
                  start: "30% bottom",
                  end: "bottom bottom",
                  //markers: true
               }
            })
         });

      });
   });

   const textAnimBoxWM = gsap.utils.toArray(".text-anim__box-w-method");

   textAnimBoxWM.forEach((text, i) => {
      const textLines = gsap.utils.toArray(".line", text);
      textLines.forEach((line, i) => {
         let delay = i + 1;
         if (delay < 10) {
            delay = "." + delay;
         }
         if (delay >= 10 && delay < 100) {
            delay = (delay + '')[0] + "." + (delay + '')[1];
         }

         line.style.animationDelay = delay + "s";

         gsap.to(line, {
            animationPlayState: 'running',
            scrollTrigger: {
               trigger: text,
               start: "90% bottom",
               end: "bottom bottom",
               //markers: true
            }
         })
      });
   });
}, 1000);