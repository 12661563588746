import gsap from "gsap";

let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {

   var mArea = document.querySelector('#magnetic-area');

   // 1. Set the function and variables
   function parallaxItBtn(e, target, movement = 0.5) {
      var boundingRect = mArea.getBoundingClientRect();
      var relX = e.pageX - boundingRect.left;
      var relY = e.pageY - boundingRect.top;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      gsap.to(target, {
         x: (relX - boundingRect.width / 2) * movement,
         y: (relY - boundingRect.height / 2 - scrollTop) * movement,
         ease: "power4",
         duration: 0.6
      });
   }

   // 2. Call the function
   function callParallaxBtn(e) {
      parallaxItBtn(e, '#magnetic-content');
      parallaxItBtn(e, '.bg-dot-burger');
   }

   mArea.addEventListener('mousemove', function (e) {
      callParallaxBtn(e);
   });

   mArea.addEventListener('mouseleave', function (e) {
      gsap.to('#magnetic-content', {
         scale: 1,
         x: 0,
         y: 0,
         ease: "power1",
         duration: 1.4
      });
      gsap.to('.bg-dot-burger', {
         x: 0,
         y: 0,
         ease: "power1",
         duration: .2
      });
   });

});